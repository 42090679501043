export const features = {
  preIncidentIndicators: 'preIncidentIndicators'
};

export const canaryFeatures = {
  test: {
    [features.preIncidentIndicators]: true
  },
  dev: {
    [features.preIncidentIndicators]: true
  },
  qa: {
    [features.preIncidentIndicators]: true
  },
  beta: {
    [features.preIncidentIndicators]: true
  },
  prod: {
    [features.preIncidentIndicators]: true
  }
};

export const TP_OBSERVATION_PILOT_PROJECTS = [
  'b4fb5a50c9e7c7bed659e0b20',
  'bcafff211289a3430cfe1a025',
  'f1720daf89ee3de12e77dd69c',
  '6fb4f7fc86976a2579632bd98',
  'c4c99d16341223d7c63c1c617',
  '9a4433aaa1b8c99c412caf4e6'
];
